import React from 'react';
import { IdTravellersAndSeatsForFlight } from '../../../model/interfaces';
import styled from 'styled-components';
import { color, scale, weight } from '../../../styles';
import { isNotPresent } from '../../../../utils/typeUtils';
import { InlineSvgIcon } from '../../InlineSvgIcon';

interface Props {
  details: IdTravellersAndSeatsForFlight | null;
  type: string;
}

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: ${scale.spacing.xxs};
`;

const SeatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const Type = styled.div`
  font-size: ${scale.font.xs};
  line-height: ${scale.lineHeight.xs};
  margin: auto;
`;

const Seats = styled.div`
  display: flex;
  font-size: ${scale.font.xxs};
  line-height: ${scale.lineHeight.xxs};
`;

const Waitlist = styled.div`
  font-size: ${scale.font.xxs};
  line-height: ${scale.lineHeight.xxs};
  margin:auto;
`;

const Text = styled.span`
  line-height: ${scale.lineHeight.m};
  font-weight: ${weight.book};
  margin: auto;
`;

const Number = styled(Text)`
  font-size: ${scale.font.l};
  line-height: ${scale.lineHeight.s};
  font-weight: ${weight.book};
  margin: 6px auto;
`;

const NumberSmall = styled(Text)`
  font-size: ${scale.font.s};
  line-height: ${scale.lineHeight.s};
  font-weight: ${weight.book};
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Icon = styled(InlineSvgIcon)`
  margin: auto;

  color: ${color.seatGray};

  &.green {
    color: ${color.seatGreen};
  }
  &.yellow {
    color: ${color.seatYellow};
  }
  &.red {
    color: ${color.seatRed};
  }

  svg {
    height: ${scale.icon.s};
  }
`;

const isBusiness = (type: string) => type === 'Business';
const isPremiumEconomy = (type: string) => type === 'Premium';

const getIconClass = (seats: string): string => {
  const seatsNr = parseInt(seats);
  if (isNaN(seatsNr)) return 'grey';
  if (seatsNr > 10) return 'green';
  if (seatsNr > 0) return 'yellow';
  return 'red';
};

export class FreeSeats extends React.Component<Props> {
  render() {
    const { details, type } = this.props;

    if (isNotPresent(details) || isNotPresent(details.waitlisted) || isNotPresent(details.seatsAvailable)) {
      return null;
    }
    let seats: string = isBusiness(type) ? details.seatsAvailable.business.toString() : isPremiumEconomy(type) ? details.seatsAvailable.premiumEconomy?.toString() : details.seatsAvailable.economy.toString();
    let waitlisted: string = isBusiness(type) ? details.waitlisted.business.toString() : isPremiumEconomy(type) ? details.waitlisted.premiumEconomy?.toString() : details.waitlisted.economy.toString();

    if (details.capacity.total === 0 && seats === '0') {
      seats = 'N/A';
      waitlisted = 'N/A';
    }

    let name: string = 'economyClass';

    if(isBusiness(type)) {
      name = 'businessClass';
    } else if (isPremiumEconomy(type)) {
      name = 'premiumEconomy';
    }

    return (
      <Wrapper>
        <IconWrapper>
          <Icon name={name} className={getIconClass(seats)} />
        </IconWrapper>
        <SeatsWrapper>
          <Seats>
            <Number>{seats}</Number>
          </Seats>
          <Type>
            <text>{type}</text>
          </Type>
          <Waitlist>
            (<NumberSmall>{waitlisted}</NumberSmall>
            <Text> waitlisted</Text>)
          </Waitlist>
        </SeatsWrapper>
      </Wrapper>
    );
  }
}
