import React from 'react';
import styled from 'styled-components';
import { color, scale, weight } from '../../../styles';
import moment from 'moment';
import { FreeSeats } from '../../../model/interfaces';
import { InlineSvgIcon } from '../../InlineSvgIcon';

interface Props {
  seats: FreeSeats;
}

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Seats = styled.div`
  font-size: ${scale.font.m};
  line-height: ${scale.lineHeight.m};
`;

const Text = styled.span`
  line-height: ${scale.lineHeight.m};
  font-weight: ${weight.book};
  font-size: ${scale.font.xxs};
  line-height: ${scale.lineHeight.xxs};
  color: ${color.darkGray};
`;

const Number = styled(Text)`
  font-size: ${scale.font.xl};
  line-height: ${scale.lineHeight.xl};
  font-weight: ${weight.book};
  margin-right: 8px;
`;

const Icon = styled(InlineSvgIcon)`
  color: ${color.black};
  svg {
    height: 20px;
  }
`;

const formatTimestamp = (timestamp: string) => {
  return moment.utc(timestamp).fromNow();
};

export class ColumnSeats extends React.Component<Props> {
  render() {
    const seats = this.props.seats;

    return (
      <Wrapper>
        <Seats>
          <Icon name="seat" />
          <Number>{seats ? seats.seats : ''}</Number>
        </Seats>
        <Text>{seats ? `(${formatTimestamp(seats.timestamp)})` : '(click to see details)'}</Text>
      </Wrapper>
    );
  }
}
