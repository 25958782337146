import * as React from 'react';
import styled from 'styled-components';
import { isPresent } from '../../../utils/typeUtils';
import { icons } from './icons';


const reduce = require('lodash/reduce');

const INLINE_ICONS = {
  emblem: icons.emblem,
  info: icons.info,
  landing: icons.landing,
  seat: icons.seat,
  takeoff: icons.takeoff,
  switch: icons.switchIcon,
  chevronDown: icons.chevronDown,
  chevronRight: icons.chevronRight,
  oneway: icons.oneway,
  economyClass: icons.economyClass,
  premiumEconomy: icons.premiumClass,
  businessClass: icons.businessClass,
  'weather-d000': icons.weatherD000,
  'weather-d100': icons.weatherD100,
  'weather-d200': icons.weatherD200,
  'weather-d210': icons.weatherD210,
  'weather-d211': icons.weatherD211,
  'weather-d212': icons.weatherD212,
  'weather-d220': icons.weatherD220,
  'weather-d240': icons.weatherD240,
  'weather-d400': icons.weatherD400,
  'weather-d410': icons.weatherD410,
  'weather-d411': icons.weatherD411,
  'weather-d412': icons.weatherD412,
  'weather-d420': icons.weatherD420,
  'weather-d422': icons.weatherD422,
  'weather-d440': icons.weatherD440,
  'weather-d500': icons.weatherD500,
  'weather-d600': icons.weatherD600,
};

export type IconName = keyof typeof INLINE_ICONS | string;
type IconMap = { [key: string]: string };

const requiredIcons: IconMap = reduce(
  INLINE_ICONS,
  (acc: IconMap, icon: string, key: IconName) => {
    acc[key] = icon;
    return acc;
  },
  {}
);

interface Props {
  name: IconName;
  ariaHidden?: boolean;
  className?: string;
  onClick?: () => void;
}

const Wrapper = styled.span`
  &.clickable {
    cursor: pointer;
  }
`;

export class InlineSvgIcon extends React.Component<Props> {
  render() {
    return (
      <Wrapper
        className={this.props.className + (isPresent(this.props.onClick) ? ' clickable' : '')}
        onClick={this.props.onClick}
        aria-hidden={this.props.ariaHidden}
        dangerouslySetInnerHTML={{ __html: requiredIcons[this.props.name] }}
      />
    );
  }
}
